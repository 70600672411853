<template>
  <div>
    <zg-border
      border-color="white"
      background-color="beige"
    />
    <zg-how-we-helped v-bind="content" />

    <zg-border
      border-color="white"
      background-color="beige"
      flipped-x
      flipped-y
    />
  </div>
</template>

<script>
  import { ZgBorder } from '@zc/components/ZgBorder'
  import { ZgHowWeHelped } from '@zc/components/ZgHowWeHelped'
  import { mapGetters } from 'vuex'
  import { getProp } from '@ocp-zmarta/zmarta-cl'

  export default {
    name: 'HowWeHelped',
    components: {
      ZgBorder,
      ZgHowWeHelped
    },
    computed: {
      ...mapGetters('router', ['getRoute']),
      content () {
        return this.$attrs?.json
      }
    },
    methods: {
      getProp
    }
  }
</script>
